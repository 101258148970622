import {Component, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {filter} from 'rxjs/operators'
import {ConfigService, SpbConfiguration} from '../services/config.service'
import {ADMIN_ROUTE_PATH} from '../application/data-types'
import {first} from 'rxjs'
import {environment} from '../../environments/environment'

@Component({
  selector: 'spb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public domain = environment.domain
  /**
   * The URL BankID shall use.
   */
  public bankIdUrl = environment.authServiceUrl


  /**
   * Constructor
   *
   * @param configService
   * @param router - To be able to get out of here.
   */
  constructor(
    public configService: ConfigService,
    private router: Router
  ) {

  }

  public ngOnInit(): void {
    this.configService.configState$.pipe(
      filter((config: SpbConfiguration) => config.ready),
      first()
    ).subscribe(
      {
        /**
         * Which route to navigate after logged in
         */
        next: () => this.router.navigate([ADMIN_ROUTE_PATH])
      })
  }
}
