import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../environments/environment'
import {BankIDVerificationData} from 'sparbanken-syd-bil'


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public getAllTransactions(): Observable<BankIDVerificationData[]> {
    const url = `${environment.bilAPIUrl}/admin`
    return this.httpClient.get<BankIDVerificationData[]>(url)
  }

}
