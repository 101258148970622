<div class="list-holder">
  <div class="flex">
    <mat-form-field>
      <mat-label>Sök</mat-label>
      <label>
        <input [formControl]="filterControl" matInput name="filterTrans">
      </label>
    </mat-form-field>
  </div>
  <div class="mat-elevation-z8">
    <table [dataSource]="dataSource" mat-table matSort
           matSortActive="timeStamp" matSortDirection="desc"
    >
      <caption>Lista över legitimeringar</caption>

      <ng-container matColumnDef="timeStamp">
        <th *matHeaderCellDef id="timeStamp" mat-header-cell
            mat-sort-header="timeStamp"> Tidpunkt
        </th>
        <td *matCellDef="let element" mat-cell>
          {{element.timeStamp}}
        </td>
      </ng-container>

      <ng-container matColumnDef="personalNumber">
        <th *matHeaderCellDef id="personalNumber" mat-header-cell mat-sort-header="personalNumber"> Personnummer
        </th>
        <td *matCellDef="let element" mat-cell>
          {{element.personalNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef id="name" mat-header-cell mat-sort-header="name"> Namn
        </th>
        <td *matCellDef="let element" mat-cell>
          {{element.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="age">
        <th *matHeaderCellDef id="age" mat-header-cell mat-sort-header="age"> Ålder
        </th>
        <td *matCellDef="let element" mat-cell>
          {{element.age}}
        </td>
      </ng-container>

      <ng-container matColumnDef="coworkerName">
        <th *matHeaderCellDef id="coworkerName" mat-header-cell mat-sort-header="coworkerName"> Personal
        </th>
        <td *matCellDef="let element" mat-cell>
          {{element.coworkerName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="copy">
        <th *matHeaderCellDef id="copy" mat-header-cell>
        </th>
        <td *matCellDef="let element" mat-cell (click)="copyInfo(element)" class="pointer">
          <span class="material-symbols-outlined">
            content_copy</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="save">
        <th *matHeaderCellDef id="save" mat-header-cell>
        </th>
        <td *matCellDef="let element" mat-cell (click)="saveInfo(element)" class="pointer">
          <span class="material-symbols-outlined">save</span>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
      <tr *matRowDef="let element; columns: columnsToDisplay" mat-row></tr>

      <tr *matNoDataRow class="no-data">
        <td [attr.colspan]="columnsToDisplay.length" class="no-data">
          {{dataSource.data.length > 0 ? 'Inga legitimeringar hittades' : 'Data laddas, vänligen vänta'}}
        </td>
      </tr>
    </table>
    <mat-paginator [length]="100"
                   [pageSizeOptions]="[5, 10, 25, 100, 500, 1000]"
                   [pageSize]="10"
                   aria-label="Select page">
    </mat-paginator>
  </div>
  <div class="popup-text" [class.visible]="copyText">{{copyText}}</div>
</div>
