import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core'
import {BankIDVerificationData} from 'sparbanken-syd-bil'
import {DataService} from '../services/data.service'
import {MatSort} from '@angular/material/sort'
import {FormControl} from '@angular/forms'
import {first, startWith, switchMap} from 'rxjs'
import {MatTableDataSource} from '@angular/material/table'
import {registerLocaleData} from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import {MatPaginator, MatPaginatorIntl} from "@angular/material/paginator"
import {UserService} from '../services/user.service'
import {SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'

registerLocaleData(localeFr)


@Component({
	selector: 'spb-id-list',
	templateUrl: './id-list.component.html',
	styleUrls: ['./id-list.component.scss']
})
export class IdListComponent implements OnInit, AfterViewInit {

	@ViewChild(MatSort) daSort = new MatSort()
	@ViewChild(MatPaginator) paginator = new MatPaginator(new MatPaginatorIntl(), ChangeDetectorRef.prototype)

	public columnsToDisplay: string[] = ['timeStamp', 'personalNumber', 'name', 'age', 'coworkerName', 'copy', 'save']
	public filterControl = new FormControl<string>('', {nonNullable: true})
	public dataSource: MatTableDataSource<BankIDVerificationData> = new MatTableDataSource<BankIDVerificationData>()
	public bid: BankIDVerificationData[] = []
	public copyText = ''

	constructor(
		private dataService: DataService,
		private userService: UserService
	) {
	}

	public ngOnInit(): void {
		this.filterControl.valueChanges.pipe(
			startWith('')
		).subscribe({
			next: (value: string) =>
				this.dataSource.filter = value.trim().toLowerCase()
		})


		this.dataService.getAllTransactions().pipe(
			switchMap((bid: BankIDVerificationData[]) => {
				this.bid = bid
				return this.userService.getUsers()
			}),
			first())
		.subscribe({
			next: (users: SparbankenUser[]) => {
				this.dataSource.data = this.bid
				.sort((a: BankIDVerificationData, b: BankIDVerificationData) => b.timeStamp - a.timeStamp)
				.map((item: any) => {

					const formattedTimeStamp = new Date(item.timeStamp).toLocaleString('sv-SE', {
						dateStyle: 'short',
						timeStyle: 'short'
					})

					return {
						...item,
						name: item.user?.name,
						age: item.user?.age,
						timeStamp: formattedTimeStamp,
						personalNumber: item.user?.personalNumber,
						coworkerName: users.find(user => user.sub === item.sub)?.name || item.sub
					}
				})
				this.dataSource.sort = this.daSort
				this.dataSource.paginator = this.paginator
			}
		})
	}

	public copyInfo(bankIDVerificationData: BankIDVerificationData) {
		const jsonData = JSON.stringify(bankIDVerificationData)

		navigator.clipboard.writeText(jsonData)
		.then(() => {
			this.copyText = 'Kopierad till urklipp'

		})
		.catch(() => {
			this.copyText = 'Kopieringen misslyckades'
		})
		setTimeout(() => {
			this.copyText = ''
		}, 1500)
	}


	public saveInfo(bankIDVerificationData: BankIDVerificationData) {
		// Remove undefined properties from the object
		for (const prop in bankIDVerificationData) {
			if (bankIDVerificationData[prop as keyof BankIDVerificationData] === undefined) {
				delete bankIDVerificationData[prop as keyof BankIDVerificationData]
			}
		}

		// Convert data to JSON format
		const json_data = JSON.stringify(bankIDVerificationData)

		/// Create the filename based on the personal identification number and name
		const sub = bankIDVerificationData.sub
		const name = (bankIDVerificationData as any).coworkerName
		const filename = `${sub}_${name}_intyg.txt`

		// Create blog and url to click
		const blob = new Blob([json_data], {type: 'application/json'})
		const url = URL.createObjectURL(blob)
		const downloadLink = document.createElement('a')
		downloadLink.href = url
		downloadLink.download = filename
		downloadLink.click()

		// Clear the URL object after the download
		URL.revokeObjectURL(url)
	}


	public ngAfterViewInit(): void {
		this.dataSource.sort = this.daSort
	}
}
